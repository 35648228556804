import React, { useEffect, useState } from "react";
import Menu from "./Menu";
import SectionArray from "./Section/SectionArray";

const ShowMore = (props) => {
  const [json, setJSON] = useState(null);

  const section = window.location.pathname.split("/")[2];

  useEffect(() => {
    const getJSON = async () =>
      fetch(`https://api.mobubby.com/json/${section}.json`).then((response) => {
        response.json().then((json) => {
          setJSON(json);
        });
      });

    getJSON();
  }, [section]);

  if (!json) return null;
  else
    return (
      <div>
        <Menu json={json} showLinks={false} />
        <div className="mt-5">
          <SectionArray key={section} section={section} articles={json} />
        </div>{" "}
      </div>
    );
};

export default ShowMore;
