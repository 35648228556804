import React, { useEffect } from "react";
import axios from "axios";

const Link = (props) => {
  useEffect(() => {
    const getJSON = async () => {
      try {
        axios
          .get(
            `https://api.mobubby.com/link?id=${
              window.location.pathname.split("/")[2]
            }`
          )
          .then((response) => {
            window.location.href = response.data.url;
          });
      } catch (e) {
        console.error("[JSON Error]", e);
      }
    };

    getJSON();
  }, []);

  return <div></div>;
};

export default Link;
