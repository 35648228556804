import { Container, Nav, Navbar } from "react-bootstrap";

const Menu = (props) => (
  <Navbar
    expand="lg"
    bg="dark"
    variant="dark"
    fixed="top"
    collapseOnSelect={true}
  >
    <Container>
      <Navbar.Brand>MoBubby</Navbar.Brand>
      {props.showLinks && (
        <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
      )}
      {props.showLinks && (
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {Object.keys(props.json)
              .sort()
              .map((section, index) => (
                <Nav.Link href={`#${section}`}>{section}</Nav.Link>
              ))}
          </Nav>
        </Navbar.Collapse>
      )}
    </Container>
  </Navbar>
);

export default Menu;
