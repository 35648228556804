import React, { useEffect, useState } from "react";
import axios from "axios";
import Ad from "./Ad";
import Menu from "./Menu";
import SectionArrayLargeCenter from "./Section/SectionArrayLargeCenter";
import SectionArrayLargeLeft from "./Section/SectionArrayLargeLeft";
import SectionArrayLargeRight from "./Section/SectionArrayLargeRight";

const Home = (props) => {
  const [json, setJSON] = useState(null);

  useEffect(() => {
    const getJSON = async () =>
      axios
        .get("https://api.mobubby.com/json/_overview.json")
        .then((response) => {
          setJSON(response.data);
        });

    getJSON();
  }, []);

  if (!json) return null;
  else
    return (
      <div>
        <Menu json={json} showLinks={true} />
        <div className="mt-5">
          {Object.keys(json)
            .sort()
            .map((section, index) => (
              <div key={section}>
                {index % 3 === 0 && (
                  <SectionArrayLargeCenter
                    section={section}
                    articles={json[section]}
                    showMore={true}
                  />
                )}
                {index % 3 !== 0 && index % 2 === 0 && (
                  <SectionArrayLargeLeft
                    section={section}
                    articles={json[section]}
                    showMore={true}
                  />
                )}
                {index % 3 !== 0 && index % 2 !== 0 && (
                  <SectionArrayLargeRight
                    section={section}
                    articles={json[section]}
                    showMore={true}
                  />
                )}
                <Ad />
              </div>
            ))}
        </div>
      </div>
    );
};

export default Home;
