import { Card } from "react-bootstrap";
import parse from "html-react-parser";
import { Facebook, Pinterest, Twitter } from "react-bootstrap-icons";

const SectionItem = (props) => (
  <a href={`/link/${props.article.id}`}>
    <Card
      bg="dark"
      text="light"
      className={props.className}
      style={{ backgroundImage: `url("${parse(props.article.imageURL)}")` }}
    >
      <Card.ImgOverlay>
        <div className="cardInfo p-3">
          <Card.Title>{parse(props.article.title)}</Card.Title>
          <Card.Footer>
            {props.article.domain.replace(/^[^:]+:\/\/(www\.)*/, "")}
            <a
              className="float-end text-light text-decoration-none px-1"
              rel="noreferrer"
              target="_blank"
              href={`http://pinterest.com/pin/create/button/?url=${encodeURI(
                `https://mobubby.com/link/${props.article.id}`
              )}`}
            >
              <Pinterest />
            </a>
            <a
              className="float-end text-light text-decoration-none px-1"
              rel="noreferrer"
              target="_blank"
              href={`http://twitter.com/share?url=${encodeURI(
                `https://mobubby.com/link/${props.article.id}`
              )}`}
            >
              <Twitter />
            </a>
            <a
              className="float-end text-light text-decoration-none px-1"
              rel="noreferrer"
              target="_blank"
              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                `https://mobubby.com/link/${props.article.id}`
              )}`}
            >
              <Facebook />
            </a>
          </Card.Footer>
        </div>
      </Card.ImgOverlay>
    </Card>
  </a>
);

export default SectionItem;
