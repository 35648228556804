import { Col, Container, Row } from "react-bootstrap";
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import parse from "html-react-parser";
import SectionItem from "./SectionItem";

const SectionArrayLargeRight = (props) => {
  const first = props.articles[0].pop();

  return (
    <Container fluid id={props.section}>
      <div className="display-3 border-bottom mb-3">
        {parse(props.section.replace("%20", " "))}
      </div>{" "}
      <Row>
        <Col sm={12} md={6}>
          <Row>
            {props.articles[0].map((article, index) => (
              <Col key={`article-${article.id}`} sm={6} className="mb-3">
                <SectionItem article={article} className="cardSmall" />
              </Col>
            ))}
          </Row>
        </Col>
        <Col sm={12} md={6}>
          <Row>
            <Col className="mb-3">
              <SectionItem article={first} className="cardLarge" />
            </Col>
          </Row>
        </Col>
        {(props.showMore || false) && (
          <div>
            <a
              href={`showmore/${props.section}`}
              className="text-decoration-none h3 float-end"
            >
              Show More <ArrowRightCircleFill />
            </a>
          </div>
        )}
      </Row>
    </Container>
  );
};

export default SectionArrayLargeRight;
